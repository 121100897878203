import { graphql } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { BlogWrapper } from "../components/BlogLayout";
import { BlogPostStyle } from "../components/BlogPostStyle";
import { titleLink } from "../components/BlogRoll";
import Content, { HTMLContent } from "../components/Content";
import Layout from "../components/Layout";
import SEO from "../components/SEO";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  title,
  helmet,
  date,
  author,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section className="section">
      {helmet || ""}
      <BlogPostStyle>
        <div className="columns">
          <div className="column is-10 is-offset-1">
            <h1 css={titleLink}>{title}</h1>

            <p>
              By <em>{author}</em> on {date}
            </p>

            <PostContent content={content} />
          </div>
        </div>
      </BlogPostStyle>
    </section>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <BlogWrapper>
        <BlogPostTemplate
          content={post.html}
          contentComponent={HTMLContent}
          author={post.frontmatter.author}
          date={post.frontmatter.date}
          helmet={<SEO frontmatter={post.frontmatter} isBlogPost />}
          title={post.frontmatter.title}
        />
      </BlogWrapper>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        author
        featuredImage {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              ...GatsbyImageSharpFixed
            }
          }
        }
        description
      }
    }
  }
`;
