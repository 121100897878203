import { css } from "@emotion/core";
import { graphql, Link, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import { BlogPostStyle } from "./BlogPostStyle";
import SEO from "./SEO";

export const titleLink = css`
  color: black;
  font-size: 2rem;
  margin-bottom: 2rem;
  text-decoration: underline;
  font-family: Rubik, sans-serif;
  font-weight: 900;
  overflow-wrap: anywhere;
`;

class BlogRoll extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <div className="columns is-multiline">
        <SEO title="Blog" />
        {posts &&
          posts.map(({ node: post }) => (
            <BlogPostStyle key={post.id}>
              <div>
                <article
                  className={`blog-list-item tile is-child box notification ${
                    post.frontmatter.featuredpost ? "is-featured" : ""
                  }`}
                >
                  <header>
                    <Link css={titleLink} to={post.fields.slug}>
                      {post.frontmatter.title}
                    </Link>
                    {/* TODO: Component for post subheading */}
                    <p>
                      By <em>{post.frontmatter.author}</em> on{" "}
                      {post.frontmatter.date}
                    </p>
                  </header>
                  <p>
                    {post.frontmatter.description}
                    <br />
                    <br />
                    <Link className="button" to={post.fields.slug}>
                      Continue reading →
                    </Link>
                  </p>
                </article>
              </div>
            </BlogPostStyle>
          ))}
      </div>
    );
  }
}

BlogRoll.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array,
    }),
  }),
};

export default () => (
  <StaticQuery
    query={graphql`
      query BlogRollQuery {
        allMarkdownRemark(
          sort: { order: DESC, fields: [frontmatter___date] }
          filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
        ) {
          edges {
            node {
              id
              fields {
                slug
              }
              frontmatter {
                title
                templateKey
                date(formatString: "MMMM DD, YYYY")
                featuredpost
                author
                description
              }
            }
          }
        }
      }
    `}
    render={(data, count) => <BlogRoll data={data} count={count} />}
  />
);
